import { FC, useState } from 'react';
import { Flex } from '@tremor/react';
import { FileContent } from 'use-file-picker';
import { FileContext } from '../contexts/fileContext'
import {SelectFileCard} from '../organisms/selectFileCard'
import { FilesCard } from '../organisms/filesCard';



export const HomePage: FC = () => {

  const [filesContent, setFilesContent] = useState<Array<FileContent>>([]);
  const [error, setError] = useState<string | undefined>(undefined);

  return <FileContext.Provider value={{filesContent, setFilesContent, error, setError}}>
    <Flex flexDirection='col' className='p-4 w-full box-border'>

      <Flex className='pt-4 justify-center'>
          <h1 className='text-3xl font-bold text-slate-900 text-center text-white'>GNX Quote Book Tool</h1>
      </Flex>

      {filesContent.length == 0 && <SelectFileCard />}
      {filesContent.length > 0 && <FilesCard />}

    </Flex>
  </FileContext.Provider>

}







