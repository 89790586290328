import { FC, useContext, useEffect } from 'react'
import { FileContext } from '../contexts/fileContext'
import { Button } from '@tremor/react';
import axios, {AxiosError, AxiosResponse} from 'axios'
import env from "react-dotenv";

export const GenerateQuoteBookButton: FC<{text: string}> = (props) => {

  const fileContext = useContext(FileContext)
  
  const generateCSV = async () => {
    fileContext.setError(undefined)
    try {
      const response: AxiosResponse = await axios.post(`${env.API_URL}/generate`,  
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          responseType: 'blob'
      });
      // download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'quote_book_csv.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error: any) {
      const text: string = await error.response.data.text()
      const json = JSON.parse(text)
      fileContext.setError(json.message)
    }
    
  }

    return <Button onClick={generateCSV} variant='secondary'>{props.text}</Button>
}
  