import {FC, useContext } from 'react'
import { Button } from '@tremor/react';
import { FileContext } from '../contexts/fileContext'
import axios from 'axios';
import env from "react-dotenv";

export const MergeButton: FC = () => {

    const fileContext = useContext(FileContext)
  
    const exportCSV = async () => {
      fileContext.setError(undefined)
      const formData: FormData = new FormData();
      const qb_file: Blob = new Blob([fileContext.filesContent[0].content]);
      const bt_file: Blob = new Blob([fileContext.filesContent[1].content]);
      formData.append("quote_book_csv", qb_file, fileContext.filesContent[0].name)
      formData.append("bt_ape_csv", bt_file, fileContext.filesContent[1].name)
      
      try {
        const response = await axios.post(`${env.API_URL}/input/bt-ape`, 
          formData, 
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            responseType: 'blob'
        })
    
        // download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'quote_book_with_bt_data.csv');
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (error: any) {
        const text: string = await error.response.data.text()
        const json = JSON.parse(text)
        fileContext.setError(json.message)
      }
    }
  
    return <Button onClick={exportCSV}>Merge and Export Quote Book</Button>
  }