import { FileContent } from "use-file-picker";
import React, { Dispatch, SetStateAction } from "react";

export interface IFileContext {
  filesContent: Array<FileContent>;
  error?: string;
  setFilesContent: Dispatch<SetStateAction<FileContent[]>>;
  setError: Dispatch<SetStateAction<string | undefined>>;
}

export const FileContext = React.createContext<IFileContext>({
  filesContent: [],
  error: undefined,
  setFilesContent: function (value: React.SetStateAction<FileContent[]>): void {
    throw new Error("Function not implemented.");
  },
  setError: function (value: React.SetStateAction<string | undefined>): void {
    throw new Error("Function not implemented.");
  },
});
