import {FC, useContext } from 'react'
import { Button } from '@tremor/react';
import { FileContext } from '../contexts/fileContext'

export const ResetButton: FC = () => {
    const fileContext = useContext(FileContext)
    const reset = () => {
      fileContext.setFilesContent([])
      fileContext.setError(undefined)
    }
    return <Button variant='secondary' onClick={reset} color='red' className='mr-2'>Restart</Button>
}