import {FC} from 'react';
import { Card, Flex, Subtitle } from '@tremor/react';
import {SelectFileButton} from '../atoms/fileSelect'
import {GenerateQuoteBookButton} from '../atoms/generateQuotebook'

export const SelectFileCard: FC = () => {
    return <Flex flexDirection='col' className='justify-center my-10'>
      <Card className='max-w-lg'>
        <Flex flexDirection='col' className='justify-center'>
          <Subtitle>To begin, please select and import a GNX quote book.</Subtitle>
          <div className='mt-4'>
          <SelectFileButton text="Import Quote Book" />
          </div>
          <Flex flexDirection='col' className='mt-4'>
            <Subtitle>Alternatively, generate a new quote book.</Subtitle>
            <div className='mt-4'>
            <GenerateQuoteBookButton text="Generate Quote Book" />
            </div>
          </Flex>
        </Flex>
      </Card>
    </Flex>
  }