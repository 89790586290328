import { FileContent } from 'use-file-picker';
import Papa from "papaparse";

export const parseTable = (file: FileContent): Promise<Array<Array<string>>> => {
    return new Promise((resolve, reject) => {
      Papa.parse(file.content, {header: true, skipEmptyLines: true, complete: (results) => {
        console.log(results)
        // parse the rows. If the data is a dict, use the values. Otherwise,
        // use the __parsed_data attr.
        const data: Array<Array<string>> = results.data.map((row:any) => {
          return Object.values(row)
        })
        
        // if the data is a dict, parse the keys as the header.
        const header: Array<string> = Object.keys(results.data[0] as string)
        data.unshift(header)
        // resolve the promise
        resolve(data)
      }})
    })
  }