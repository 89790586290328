import { FC, useContext, useEffect } from 'react'
import { FileContext } from '../contexts/fileContext'
import { Button } from '@tremor/react';
import { useFilePicker, } from 'use-file-picker';

export const SelectFileButton: FC<{text: string}> = (props) => {

    const fileContext = useContext(FileContext)
  
    const [openFileSelector, { filesContent, loading }] = useFilePicker({
      accept: '.csv',
    });
  
    useEffect(() => {
      fileContext.setFilesContent(fileContext.filesContent.concat(filesContent))
    }, [filesContent])
  
    return <Button onClick={() => openFileSelector()}>{props.text}</Button>
  }
  