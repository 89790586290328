import {FC, useContext, useState } from 'react'
import { FileContext } from '../contexts/fileContext'
import { 
    Card, 
    Flex, 
    TabList,
    Tab
  } from '@tremor/react';
import {ResetButton} from '../atoms/resetButton'
import { MergeButton } from '../atoms/mergeButton';
import {QBOnlyButtons} from '../molecules/qbOnlyButtons'
import { GNXTable } from '../organisms/table';

export const FilesCard: FC = () => {
  
    const fileContext = useContext(FileContext);
    const [showTab, setShowTab] = useState<string>("qb");
  
    return <Flex flexDirection='col' className='justify-center my-10'>
      <Card className=''>

        <Flex flexDirection='row' className='justify-between'>
          <Flex className='text-red-500'>
             {fileContext.error && `Error: ${fileContext.error}`}
          </Flex>
          <Flex flexDirection='row' className='justify-end'>
            <ResetButton />
            {fileContext.filesContent.length <= 1 && <QBOnlyButtons />}
            {fileContext.filesContent.length > 1 && <MergeButton />}
          </Flex>
        </Flex>

        <TabList 
          defaultValue='qb'
          onValueChange={setShowTab}>
          { fileContext.filesContent.map((file, index) => {
            return <Tab key={index} value={index == 0 ? 'qb' : 'bt'} text={`${index == 0 ? 'Quote Book' : "BT"} (${file.name})`} />
          })}
        </TabList>
  
        {showTab === 'qb' && <GNXTable key='qb' file={fileContext.filesContent[0]} />}
        {showTab === 'bt' && <GNXTable key='bt' file={fileContext.filesContent[1]} />}
      </Card>
    </Flex>
  }