import { FC, useEffect, useState } from 'react';
import {parseTable} from '../particles/parse';
import { FileContent } from 'use-file-picker';
import InfinateScroll from 'react-infinite-scroller'
import { 
    Table,
    TableHead,
    TableHeaderCell,
    TableRow,
    TableCell,
  } from '@tremor/react';

export const GNXTable: FC<{file: FileContent}> = (props) => {

    const [tableRows, setTableRows] = useState<Array<Array<string>>>([]);
    const [tableHeader, setTableHeader] = useState<Array<string>>([]);
    const [currentlyShowing, setCurrentlyShowing] = useState<number>(20);

    const fetchData = async () => {
      const table: Array<Array<string>> = await parseTable(props.file)
        setTableRows(table.slice(1))
        setTableHeader(table[0])
    }

    const increment = () => {
      setCurrentlyShowing(currentlyShowing + 20)
    }
  
    useEffect(() => {
      fetchData()
    }, [])
  
    return <Table className='mt-3'>
    {
      <TableHead>
        <TableRow>
          {tableHeader.map((column, index) => {
            return <TableHeaderCell key={index} className='px-4'>{column}</TableHeaderCell>
          })}
        </TableRow>
      </TableHead>
    }
    {
      
        <InfinateScroll 
          pageStart={0}
          loadMore={increment}
          hasMore={currentlyShowing < tableRows.length}
          element={'tbody'}
          loader={<tr><td><h4>Loading...</h4></td></tr>}
        >
            {tableRows.slice(0, currentlyShowing).map((row: Array<string>, index) => {
              return <TableRow key={index}>
                {row.map((column, jdex) => {
                  return <TableCell key={jdex}>{column}</TableCell>
                })}
              </TableRow>
            })}
        </InfinateScroll>
      
    }
  
  </Table>
  }

