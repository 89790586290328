import {FC, useContext } from 'react'
import { Button } from '@tremor/react';
import { FileContext } from '../contexts/fileContext'
import axios, {AxiosError, AxiosResponse} from 'axios'
import env from "react-dotenv";
import {SelectFileButton} from '../atoms/fileSelect'

export const QBOnlyButtons: FC = () => {

    const fileContext = useContext(FileContext)
  
    const exportXML = async () => {
      const formData: FormData = new FormData();
      const file: Blob = new Blob([fileContext.filesContent[0].content]);
      formData.append("quote_book_csv", file, fileContext.filesContent[0].name)
      fileContext.setError(undefined)
      try {
        const response: AxiosResponse = await axios.post(`${env.API_URL}/output/bt-ape`, 
          formData, 
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            responseType: 'blob'
        });
        // download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'quote_book_csv_and_bt_xml.zip');
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (error: any) {
        const text: string = await error.response.data.text()
        const json = JSON.parse(text)
        fileContext.setError(json.message)
      }
      
    }
  
    return <>
      <Button onClick={exportXML} className='mr-2'>Export Quote Book as XML</Button>
      <SelectFileButton text="Import BT CSV" />
    </>
  }